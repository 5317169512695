<template>
  <div class="box">
    <!-- 头部 -->
    <headers></headers>
    <!-- 轮播图 -->
    <el-carousel :interval="3000">
      <el-carousel-item>
        <img src="@/assets/homePage/ja-关于盛泰banner.png" class="images">
      </el-carousel-item>
    </el-carousel>
    <div class="content">
      <div class="top_text">
        <el-card>
          <h2 class="H2">会社概要</h2>
          <img class="img_1" src="@/assets/about/culture/企业文化图1.png" alt="">
          <img class="img_2" src="@/assets/about/culture/企业文化图2.png" alt="">
          <img class="img_3" src="@/assets/about/culture/ja-修饰文字.png" alt="">
          <p v-for="item in text_top" :key="item.id" style="text-indent: 2em;">{{ item.text_ }}</p>
        </el-card>
      </div>
      <div class="bottom_text">
        <el-card>
          <h2 class="H2">企業理念</h2>
          <img src="@/assets/about/culture/ja-企业文化核心内容.png" alt="">
          <p v-for="item in text_bottom" :key="item.id" style="text-indent: 2em;">{{ item.text_ }}</p>
        </el-card>
      </div>
    </div>

    <!-- 底部 -->
    <foot></foot>
  </div>
  <div class="_div">
    <!-- 头部 -->
    <move_header></move_header>
    <!-- 轮播图 -->
    <van-swipe :autoplay="3000" indicator-color="white">
      <van-swipe-item>
        <img src="@/assets/homePage/ja-关于盛泰banner.png" style="width: 100%;">
      </van-swipe-item>
    </van-swipe>
    <!-- 中间内容区域 -->
    <div class="content">
      <h2>会社概要</h2>
      <img class="img_1" src="@/assets/about/culture/企业文化图1.png" alt="">
      <img class="img_2" src="@/assets/about/culture/企业文化图2.png" alt="">
      <p v-for="item in text_top" :key="item.id" style="text-indent: 2em;">{{ item.text_ }}</p>
     <h2>企業理念</h2>
     <img src="@/assets/about/culture/ja-企业文化核心内容.png" alt=""/>
      <p v-for="item in text_bottom" :key="item.id" style="text-indent: 2em;">{{ item.text_ }}</p>
    </div>
    <!-- 底部 -->
    <move_foot></move_foot>

  </div>
</template>

<script>
import foot from '../components/foot.vue'
import headers from '../components/header.vue'
import move_foot from "../move_components/move_foot.vue";
import move_header from "../move_components/move_header.vue";
import { toRefs, reactive, ref, onMounted } from 'vue'
import { useRoute } from "vue-router";
export default {
  components: { foot, headers, move_foot, move_header },
  setup() {
    const Route = useRoute()
    const state = reactive({
      dataList: [],
      data: {},
      text_top: [
        { id: 1, text_: '盛泰光電は2006年7月に深センで創立し、2010年7月に江西会社を創立し、2018年4月に重慶会社を創立し、2019年にインド会社を創立しました。現在、重慶(本社+拠点)センター、深セン(研究開発+販売)センター、江西+インド製造基地、台湾研究開発センター、シンガポール研究開発センター、およびアメリカ事務所の全体配置を形成しています。' },
        { id: 2, text_: '会社は創立以来、ずっと急速な成長を維持し、2020年の生産額は36億元を実現し、2021年の生産額は40億元を超え、社会に3,000余りの雇用を提供し、今後3-4年以内に100億の生産額を実現するよう努力しています。' },
        { id: 3, text_: '2020年、会社は260ムーの敷地面積の盛泰光電科学技術企業孵化園を建設することを計画しています。当時、一期の建設投資は10億元で、建築面積は約83000㎡で、その中、製品技術研究開発センターは8000㎡で、連合実験室は2000㎡で、生産センターは40000㎡で、ワンセットの事務用部屋は10000㎡で、生活ワンセットの部屋は23000㎡です。そして、2022年に実用化されました。' },
        { id: 4, text_: '会社は近代化生産ライン85本、COBチップパッケージライン40本余りを新設し、超高精度AA生産ラインを多数購入し、カメラ生産能力は5億個/年に達し、既存のハイエンドカメラモジュール生産工場はすべて百級、千級無塵工場にアップグレードしました。同時に、『中国製造2025』という国家戦略を依拠して、自動化、知能化生産を迅速に推進しており、工業4.0標準を参照して、ハイテク、知能化、デジタル化製造企業へと邁進しています。' }
      ],
      text_bottom: [
        { id: 1, text_: '盛泰光電は国内初のカメラモジュール製造企業として、実力のある研究開発能力と製造能力を持っている。創立以来、ずっと携帯電話カメラモジュールの研究開発、製造、販売とサービスに注目し、そして非携帯電話にノートパソコン、車載、医療、AIOTなどの領域を含み、携帯電話カメラモジュール領域を主とし、非携帯電話カメラ多領域の新しい発展構造を形成した。現在、カメラモジュールの出荷量は世界で第五位です;' },
        { id: 2, text_: '創立の初め、盛泰光電の人はずっと本分を持ち、奮闘し、共有、創造した核心価値観を堅持し、光電業界に立脚し、国際一流ブランドのお客様の最適な選択となるを企業使命とし、志を同じくする人を探し、一生と言えることをすると企業精神を遂行し、「ファイター」を本とし、「お客様」を核心とし、「品質」を基礎とし、「技術」及び「研究開発」によって、永遠に発展する行為の準則を遂行した。強をして、遠くへ行って、100年の光電企業をします。' }
      ]
    })
    let methods = {}
    onMounted(() => {

    })
    return {
      ...toRefs(state), ...methods
    }
  }
}
</script>

<style scoped lang="less">
.box {

  width: 100%;

  .content {
    max-width: 1920px;
    margin: 50px auto;
    width: 70%;
    height: auto;
    p{font-size: 1.25rem;}
    .H2 {
      font-size: 1.7rem;
    }

    .H2::before {
      content: "";
      width: 30%;
      height: 2px;
      background-color: #c7000b;
      position: absolute;
      margin-top: 40px;
      left: 6%;
      transform: translateX(-50%);
      // top:55px;
      min-width: 35px;
      max-width: 35px;
    }

    .top_text {
      box-sizing: border-box;
      position: relative;

      p {
        padding: 0 30px;
        Letter-spacing: 1px;
      }

      .img_1,
      .img_2 {
        width: 49%;
        border-radius: 15px;
      }

      .img_1 {
        margin-bottom: 0px;
      }

      .img_2 {
        margin: -40px 0 40px 20px;
      }

      .img_3 {
        width: 100%;
        margin: -1.56rem 0 0 -0px;
      }
    }

    .bottom_text {
      margin-top: 30px;
      position: relative;

      img {
        width: 100%;
        margin: 20px 0;
      }

      p {
        padding: 0 30px;
        Letter-spacing: 1px;
      }
    }
  }
}

.images {
  width: 100%;
  height: auto;
  max-height: 600px
}

::v-deep .el-carousel {
  max-width: 1920px;
  margin: 0 auto;
  overflow-y: hidden;
  height: auto;
  max-height: 600px;
  // max-height: 700px;
}

::v-deep .el-carousel__container {
  height: 100% !important;
  min-height: 500px;
}

.el-carousel {
  max-width: 1920px;
  margin: 0 auto;
}

::v-deep .el-card {
  border-radius: 8px;
}

// 小屏幕区域
@media only screen and (min-width: 767px) {
  .box {
    display: block;
  }

  ._div {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .box {
    display: none;
  }

  ._div {
    display: block;

    .content {
      padding: 30px;
      box-sizing: border-box;
      .img_2{
        margin-top: 50px;
      }
      p{
        font-size: 60px;
      }
      img{
        width: 100%;
        border-radius: 15px;
        overflow: hidden;
      }
            h2 {
        text-align: center;
        font-size: 80px;
      }



      h2::before {
        content: "";
        width: 30%;
        height: 8px;
        background-color: #c7000b;
        position: absolute;
        margin-top: 100px;
        left: 50%;
        transform: translateX(-50%);
        min-width: 100px;
        max-width: 100px;
      }
    }
  }
}
</style>